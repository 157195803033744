<template>
  <div id="app">
    <div class="es-container">
      <div class="area-menu">
        <div class="area-left-logo">
          <router-link v-if="$session.exists()" to="/"><img src="./assets/logo.png" width="150px"></router-link>
        </div>
        <div class="area-left-menu">
          <es-menu v-if="$session.exists()" :anchors="links" :open="menu_open"></es-menu>
        </div>
        <div class="area-left-foot">
          <!-- <div v-if="$session.exists()" class="button is-success is-outlined is-fullwidth" @click="logout()">Logout</div> -->
          <div class="dropdown is-up es-fullwidth is-hoverable">
            <div class="dropdown-trigger es-fullwidth">
              <button class="button is-link is-fullwidth is-outlined" aria-haspopup="true" aria-controls="dropdown-menu7">
                <span class="icon">
                  <icon name="user"></icon>
                </span>
                <span>{{ $session.get('nome_usuario') }}</span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu7" role="menu">
              <div class="dropdown-content">
                <a href="#" class="dropdown-item" @click="logout()">
                  Sair
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="area-main">
        <router-view/>
      </div>
    </div>
    <notifications position="top center" />
  </div>
</template>

<script>
import EsMenu from '@/components/EsMenu'

export default {
  name: 'app',
  data () {
    return {
      menu_open: false,
      links: [
        {to: '/gravacao', acesso: 'GR001', text: 'Gravação', subMenu: [{to: '/gravacao', acesso: 'GR001', text: 'Gravação'}, {to: '/gravacao/gravacaoteste', acesso: 'GR002', text: 'Gravação Teste'}]},
        {to: '/custas', acesso: 'CT001', text: 'Emolumentos', subMenu: [{to: '/custas', acesso: 'CT0010', text: 'Gerenciar'}, {to: '/custas/excluidas', acesso: 'CT002', text: 'Emolumentos Excluidos'}]},
        {to: '/conciliacao', acesso: 'CT003', text: 'Conciliação'},
        {to: '/valoresPendentes', acesso: 'EX001', text: 'Valores Pendentes'},
        {to: '/faturamento', acesso: 'FT001', text: 'Faturamento'},
        {to: '/liquidacao', acesso: 'LQ001', text: 'Liquidação', service: true, icon: true, subMenu: [{to: '/liquidacao', acesso: 'LQ001', text: 'Gerenciar', service: true, icon: true}, {to: '/VisualizarLiquidacao', acesso: 'VLQ001', text: 'Visualizar Liquidação', service: true, icon: true}]},
        {to: '/solicitacao', acesso: 'SL001', text: 'Solicitação', service: true},
        {to: '/pagamentos', acesso: 'PG001', text: 'Pagamentos', service: true, icon: true, subMenu: [{to: '/pagamentos/resgatedevalores', acesso: 'RV001', text: 'Comprovantes Resgate de Valores'}]},
        {to: '/pendentes', acesso: 'PD001', text: 'Pendentes', service: true},
        {to: '/aprovacao', acesso: 'AP001', text: 'Aprovação', service: true},
        {to: '/pagfor', acesso: 'PF001', text: 'Pagfor', service: true},
        {to: '/arquivo', acesso: 'AQ001', text: 'Arquivo', subMenu: [{to: '/arquivo', acesso: 'AQ001', text: 'Gerenciar'}, {to: '/arquivo/retorno/santander', acesso: 'AQ002', text: 'Arq. Retorno Santander'}, {to: '/arquivo/comprovante/santander', acesso: 'AQ003', text: 'Gerar Comprovante Santander'}]},
        {to: '/movimento', acesso: 'AT001', text: 'Movimento'},
        {to: '/configuracao', acesso: 'CF001', text: 'Configuração', subMenu: [{to: '/configuracao', acesso: 'CF001', text: 'Gerenciar'}, {to: '/configuracao/ufs', acesso: 'CF002', text: 'UFs'}, {to: '/configuracao/contas', acesso: 'CF003', text: 'Contas'}]}
      ]
    }
  },
  components: { EsMenu },
  methods: {
    toggleMenu () {
      this.menu_open = !this.menu_open
    }
  }
}
</script>

<style lang="scss">

$menu-item-hover-background-color: #efefef;
$menu-item-hover-color: #206892;


@import '../node_modules/bulma/bulma.sass';
@import 'assets/sass/financeiro.sass';

.vue-notification {
  padding: 10px;
  margin: 10px 5px 5px;

  font-size: 12px;

  color: #ffffff;
  background: #44A4FC;
  border-left: 5px solid #187FE7;

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #E54D42;
    border-left-color: #B82E24;
  }

  &.success {
    background: #68CD86;
    border-left-color: #42A85F;
  }
}

.es-fullwidth {
  width: 100%;
}

.button {
  margin: 5px !important;
}

</style>
