<template>
  <div>
    <es-title title="Resgate de Valores">
      <div slot="left">
        <div v-if="tab == 1" class="button" @click="tab = 0, get()">Voltar</div>
      </div>
      <div slot="right">
        <div v-if="tab == 0">
          <div class="button is-danger" @click="toggleModalImportacao()">Importação de comprovantes</div>
        </div>
      </div>
    </es-title>
    <es-modal title="Importação de Arquivos" :config="modalImportacao">
      <div class="field">
        <div class="file is-centered is-boxed is-success has-name">
          <label class="file-label">
            <input class="file-input" type="file" @change="importacaoZIP" accept="application/x-zip-compressed">
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label">
                Selecionar arquivo ZIP
              </span>
            </span>
          </label>
        </div>
      </div>
      <div class="field">
        <div class="file is-centered is-boxed is-success has-name">
          <label class="file-label">
            <input class="file-input" type="file" @change="importacaoXLSX" accept=".xlsx, .xls">
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label">
                Selecionar arquivo XLSX
              </span>
            </span>
          </label>
        </div>
      </div>
      <div class="field">
        <button class="button is-success" :disabled="!isEnviarAtivo" @click="enviarArquivos">Enviar</button>
      </div>
    </es-modal>
    <div>
      <es-table :config="tabela" :rows="arquivos" @rowclick="ver_download"></es-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data () {
    return {
      tab: 0,
      arquivos: [],
      isEnviarAtivo: false,
      modalImportacao: {
        active: false
      },
      tabela: {
        fields: [
          { name: 'nome', label: 'Nome do Arquivo', align: 'left' },
          { name: 'data', label: 'Data e Hora do Download', type: 'text' },
        ],
      },
      loading: false, 
      arquivoZIP: null, 
      arquivoXLSX: null,
    };
  },
  methods: {
    toggleModalImportacao() {
      this.modalImportacao.active = !this.modalImportacao.active;
    },

    importacaoZIP(event) {
      this.showLoading();
      this.arquivoZIP = event.target.files[0];
      this.verificarEnvio();
    },

    importacaoXLSX(event) {
      this.showLoading();
      this.arquivoXLSX = event.target.files[0];
      this.verificarEnvio();
    },

    verificarEnvio() {
      if (this.arquivoZIP && this.arquivoXLSX) {
        this.isEnviarAtivo = true;
      } else {
        this.isEnviarAtivo = false;
      }
    },
    enviarArquivos() {
      let formData = new FormData();
      formData.append('zip', this.arquivoZIP);
      formData.append('xls', this.arquivoXLSX);

      const nomeArquivo = 'unificado_' + new Date().toLocaleDateString() + '.zip'; 

      axios.post('api/v1/batimento/resgatedevalores/arquivos', formData, { responseType: 'blob' })
        .then((res) => {

          let downloadUrl = window.URL.createObjectURL(res.data);
          let link = document.createElement('a');
          link.href = downloadUrl;
          link.download = nomeArquivo; 
          link.click();
          window.URL.revokeObjectURL(downloadUrl);


          const dataHora = new Date();
          const dataFormatada = dataHora.toLocaleDateString();
          const horaFormatada = dataHora.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }); 


          this.arquivos.push({
            nome: nomeArquivo, 
            data: `${dataFormatada} ${horaFormatada}`,  
          });

          this.closeLoading();
        })
        .catch((err) => {
          console.error('Erro ao fazer download', err);
          this.closeLoading();
        });
    },

    showLoading() {
      this.loading = true;
    },

    closeLoading() {
      this.loading = false;
    },

  },
};
</script>

<style lang="scss">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  text-align: center;
}

.table-container {
  width: 80%;
  margin-top: 30px;
  text-align: center;
}

.button.is-success {
  background-color: #23d160;
  border-color: #23d160;
}

.file-cta {
  background-color: #23d160;
  color: white;
}

.file-name {
  margin-left: 10px;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.21) !important;
}

.area-main {
  display: block !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.q-spinner {
  font-size: 40px;
  color: #00d1b2;
}
</style>
